<template>
  <transition name="fade" v-if="cameraActive">
    <section class="screen-modal camera-modal">
      <div class="modal-backdrop"></div>
      <div class="modal-content">
        <section class="modal-inner-content">
          <div ref="cameraVideo" id="video" class="video"></div>
          <canvas
            ref="photocanvas"
            id="take-photo-canvas"
            width="373"
            height="280"
          ></canvas>
          <div class="clebex-take-photo-container">
            <div class="clebex-take-photo-ring">
              <button class="action-btn take-photo" @click="takePhoto">
                <!-- {{ displayLabelName("profile", "profile", "take-photo") }} -->
              </button>
            </div>
          </div>
        </section>
        <footer class="modal-footer">
          <ul class="cameras" :class="{ active: camerasMenu }">
            <li v-for="camera in cameras" :key="camera.id" class="camera-item">
              <button class="scan-button" @click="getUserMedia(camera.id)">
                {{ camera.label }}
              </button>
            </li>
          </ul>
          <nav class="actions-menu theme-gray">
            <ul class="actions-list">
              <li class="action">
                <button class="action-btn" @click="cancelStream">
                  {{ displayLabelName("plan", "plan", "cancel") }}
                </button>
              </li>
              <template v-if="streaming && zoom">
                <li class="action">
                  <button class="action-btn" @click="increaseZoom">
                    {{ displayLabelName("plan", "plan", "zoom-in") }}
                  </button>
                </li>
                <li class="action">
                  <button class="action-btn" @click="deceaseZoom">
                    {{ displayLabelName("plan", "plan", "zoom-out") }}
                  </button>
                </li>
              </template>
              <li
                class="action"
                v-if="cameras && cameras.length > 0 && streaming"
              >
                <button class="action-btn" @click="toggleCameraMenu">
                  {{ displayLabelName("plan", "plan", "camera") }}
                </button>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </section>
  </transition>
</template>

<script>
import { errorHandler } from "@/services/error-handler";
import { mapState } from "vuex";

export default {
  name: "TakePhotoInterface",
  data() {
    return {
      cameras: [],
      camerasMenu: false,
      zoom: null,
      zoomMin: 0,
      zoomMax: 0,
      zoomStep: 0,
      track: null,
      supportsCameraApi:
        "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices,
      streaming: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getUserMedia();
      this.getDevices();
    });
  },
  watch: {
    $route() {
      this.cancelStream();
    }
  },
  computed: {
    ...mapState("camera", ["cameraActive", "recordingStarted"])
  },
  methods: {
    displayGenericMessage(error) {
      return errorHandler({
        errorObject: {
          data: {
            message: error
              ? error
              : this.displayLabelName("profile", "profile", "camera-code-error")
          }
        }
      });
    },
    setZoomOptions(track) {
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();
      if ("zoom" in settings) {
        this.zoom = settings.zoom;
        this.zoomMin = capabilities.zoom.min;
        this.zoomMax = capabilities.zoom.max;
        this.zoomStep = capabilities.zoom.step;
      }
    },
    increaseZoom() {
      if (this.zoom < this.zoomMax) {
        this.zoom += this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }]
        });
      }
    },
    deceaseZoom() {
      if (this.zoom > this.zoomMin) {
        this.zoom -= this.zoomStep;
        this.track.applyConstraints({
          advanced: [{ zoom: this.zoom }]
        });
      }
    },
    async getUserMedia(deviceId) {
      this.camerasMenu = false;
      if (this.supportsCameraApi) {
        const self = this;
        let constraints = {
          audio: false,
          video: true,
          focusMode: "continuous"
        };
        if (deviceId) {
          constraints.deviceId = deviceId;
        } else {
          constraints = {
            ...constraints,
            video: {
              facingMode: "environment"
            }
          };
        }
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function(stream) {
            self.track = stream.getVideoTracks()[0];
            self.setZoomOptions(self.track);
            self.handleStream(stream);
          })
          .catch(function(err) {
            self.displayGenericMessage(err);
          });
      }
    },
    async getDevices() {
      if (this.supportsCameraApi) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = [];
        devices.forEach(function(device) {
          if (device.kind === "videoinput") {
            videoDevices.push({
              id: device.deviceId,
              label: device.label
            });
          }
        });
        this.cameras = videoDevices;
      }
    },
    handleStream(stream) {
      const self = this;
      const element = this.$refs.cameraVideo;
      let video = element.querySelector("video");

      if (!video) {
        video = document.createElement("video");
        video.style.width = "100%";
        video.style.height = "100%";
        video.setAttribute("muted", true);
        video.playsInline = true;
        element.append(video);
        video.addEventListener("canplay", self.setStreaming, false);
      }
      video.srcObject = stream;
      video.play();
    },
    setStreaming() {
      if (!this.streaming) {
        const video = this.$refs.cameraVideo.querySelector("video");
        let width = 373;
        let height = 0;
        height = video.videoHeight / (video.videoWidth / width);
        video.setAttribute("width", width);
        video.setAttribute("height", height);
        this.streaming = true;
      }
    },
    takePhoto() {
      const video = this.$refs.cameraVideo.querySelector("video");
      this.$refs.photocanvas
        .getContext("2d")
        .drawImage(
          video,
          0,
          0,
          this.$refs.photocanvas.width,
          this.$refs.photocanvas.height
        );
      const imageDataUrl = this.$refs.photocanvas.toDataURL("image/jpeg");
      this.$store.commit("camera/setPhotoImageUrl", imageDataUrl, {
        root: true
      });
      this.cancelStream();
    },
    resetState() {
      this.cameras = [];
      this.streaming = false;
      this.zoom = null;
      this.zoomMin = 0;
      this.zoomMax = 0;
      this.zoomStep = 0;
      this.track = null;
      this.$store.commit("camera/setCameraActive", false, { root: true });
      this.$store.commit("camera/setCameraScreenId", null, { root: true });
    },
    cancelStream() {
      const videoEl = this.$refs.cameraVideo.querySelector("video");
      this.track && this.track.stop();
      if (videoEl) {
        videoEl.removeEventListener("canplay", this.setStreaming);
        videoEl.remove();
      }
      this.resetState();
    },
    toggleCameraMenu() {
      if (!this.camerasMenu) {
        this.track.stop();
      }
      this.camerasMenu = !this.camerasMenu;
    }
  },
  beforeUnmount() {
    this.cancelStream();
  }
};
</script>
